
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonIcon,
  IonText,
  IonLabel,
  IonItem,
  IonMenuButton,
  IonMenuToggle,
  IonButtons,
  IonImg,
  IonProgressBar
} from '@ionic/vue';
import { calendar, chevronForwardOutline, chevronBackOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

// Import Swiper Vue.js components
import Swiper from 'swiper';

// Import Swiper styles
import 'swiper/css/swiper.css';

export default defineComponent({
  name: 'Competitive',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonIcon,
    IonText,
    IonLabel,
    IonItem,
    IonMenuButton,
    IonMenuToggle,
    IonButtons,
    IonImg,
    IonProgressBar
  },
  setup(){
    return{
      calendar,
      chevronForwardOutline,
      chevronBackOutline
    }
  },
  data(){
    return{
      server: 'https://admin.enelcine.com.ar/api',
      isLoading: false,
      col: [] as any[any]
    }
  },
  mounted(){
      this.loadPage()
  },
  methods:{
    loadPage: async function(){
      this.isLoading = true

      let date = this.getThu('this')
      
      const response = await this.getItems('peliculas', null, 'filter[estado][eq]=publicado&filter[fecha_local][gte]='+date+'&sort=fecha_local,distribuidora&limit=-1')

      const items: any = response.data

      const col: any = []
      let index = -1

      items.forEach( (movie: any) => {
        if(movie.fecha_local != date){
          index++
          date = movie.fecha_local
          col[index] = []
          col[index].date = date
          col[index].items = []
        }

        col[index].items.push(movie)
      });

      this.col = col

      const slideOpts = {
        observer: true,
        parentObserver: true,
        slidesPerView: 3,
        spaceBetween: 0,
        freemode: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
					768: {
						slidesPerView: 4
					},
					1024: {
						slidesPerView: 6
					}
				}
      }
      
      setTimeout( () => {
          new Swiper(this.$refs.competitive as any, slideOpts)
      }, 101)

      this.isLoading = false
    },
    getItems: async function(category: string, limit: any, filter: string) {

      let endpoint

      endpoint = '/items/'+category+'?fields=*,portada.*,poster.*,genero.generos_id.nombre,distribuidora.*'

      if(filter !== null){
        endpoint+= '&'+filter
      }

      if( limit !== null ){
        endpoint+= '&limit='+limit
      }

      const response = await this.axios.get(this.server + endpoint)

      const items: any = response.data

      if(category == "peliculas"){
        items.data.forEach((movie: any,index: number) => {
            items.data[index].titulo = (movie.titulo_local) ? movie.titulo_local : movie.titulo_original

            if(movie.fecha_local !== null){
              items.data[index].fecha_local = this.getFormatedDate(movie.fecha_local)
            }else{
              items.data[index].fecha_local = 'A Confirmar'
            }

            /*if(movie.poster === null){
              items.data[index].poster = this.config.imagen_no_disponible
            }*/
        })
      }

      return items
    },
    getFormatedDate: function (date: any){
      date = new Date(date+'T12:00:00.000Z')
      date = new Intl.DateTimeFormat('es-ES', {dateStyle: 'medium', timeZone: 'America/Argentina/Buenos_Aires'}).format(date)
      return date
    },
    getThu: function(week: string){
      // set dates
      const today = new Date()
      let thisThu = new Date()
      let nextThu = new Date()
      const thuNmb = 4
      let todayNmb= today.getDay()

      // set number for sunday
      if( todayNmb == 0 ){
        todayNmb = 7
      }

      // if not default day number
      if( todayNmb != 4 ){
        const diff = ( todayNmb > thuNmb) ? (todayNmb - thuNmb) * -1 : thuNmb - todayNmb
        thisThu.setDate(today.getDate() + diff)
      }else{
        thisThu = today
      }

      // next thu
      nextThu.setDate(thisThu.getDate() + 7)

      let dd, mm, yyyy

      dd = String(thisThu.getDate()).padStart(2, '0')
      mm = String(thisThu.getMonth() + 1).padStart(2, '0')
      yyyy = thisThu.getFullYear()
      thisThu = yyyy + '-' + mm + '-' + dd as any 

      dd = String(nextThu.getDate()).padStart(2, '0')
      mm = String(nextThu.getMonth() + 1).padStart(2, '0')
      yyyy = nextThu.getFullYear();
      nextThu = yyyy + '-' + mm + '-' + dd as any 

      if( week == 'this'){
        return thisThu
      }else{
        return nextThu
      }
    }
  }
});
