<template>
  <ion-page>
    <!-- HEADER -->
    <ion-header>
      <ion-toolbar color="dark">
        <!-- TITLE -->
        <ion-item class="page-title ion-no-padding" lines="none" color="dark">
          <ion-icon slot="start" size="small" :icon="calendar" color="dark"></ion-icon>
          <ion-label>
            <ion-text color="primary">
              <h2><b>Competitive</b></h2>
            </ion-text>
          </ion-label>
        </ion-item>
        <!-- BUTTONS -->
        <ion-buttons slot="start">
          <ion-menu-toggle>
            <ion-menu-button color="primary"></ion-menu-button>
          </ion-menu-toggle>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    
    <ion-content>
        <div class="calendar swiper" ref="competitive" v-if="col.length > 0">
          <div class="swiper-wrapper">
            <div class="col swiper-slide" v-for="(n, index) in col.length" :key="index">
              <div class="date">
                    {{col[index].date}}
              </div>
              <div v-for="(movie, movieIndex) in col[index].items" :key="movieIndex">
                <router-link :to="'/materiales/'+movie.slug" class="item">
                  <div class="header" v-if="movie.distribuidora">{{movie.distribuidora.nombre}}</div>
                  <div class="title">{{movie.titulo}}</div>
                </router-link>  
              </div>
            </div>
          </div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>

        <div id="loading" v-else>
          <div class="loading-container">
            <ion-img src="/assets/logo.svg" class="logo"></ion-img>
            <ion-progress-bar mode="ios" type="indeterminate"></ion-progress-bar>
          </div>
        </div>
      <!--/section-->
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonIcon,
  IonText,
  IonLabel,
  IonItem,
  IonMenuButton,
  IonMenuToggle,
  IonButtons,
  IonImg,
  IonProgressBar
} from '@ionic/vue';
import { calendar, chevronForwardOutline, chevronBackOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

// Import Swiper Vue.js components
import Swiper from 'swiper';

// Import Swiper styles
import 'swiper/css/swiper.css';

export default defineComponent({
  name: 'Competitive',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonIcon,
    IonText,
    IonLabel,
    IonItem,
    IonMenuButton,
    IonMenuToggle,
    IonButtons,
    IonImg,
    IonProgressBar
  },
  setup(){
    return{
      calendar,
      chevronForwardOutline,
      chevronBackOutline
    }
  },
  data(){
    return{
      server: 'https://admin.enelcine.com.ar/api',
      isLoading: false,
      col: [] as any[any]
    }
  },
  mounted(){
      this.loadPage()
  },
  methods:{
    loadPage: async function(){
      this.isLoading = true

      let date = this.getThu('this')
      
      const response = await this.getItems('peliculas', null, 'filter[estado][eq]=publicado&filter[fecha_local][gte]='+date+'&sort=fecha_local,distribuidora&limit=-1')

      const items: any = response.data

      const col: any = []
      let index = -1

      items.forEach( (movie: any) => {
        if(movie.fecha_local != date){
          index++
          date = movie.fecha_local
          col[index] = []
          col[index].date = date
          col[index].items = []
        }

        col[index].items.push(movie)
      });

      this.col = col

      const slideOpts = {
        observer: true,
        parentObserver: true,
        slidesPerView: 3,
        spaceBetween: 0,
        freemode: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
					768: {
						slidesPerView: 4
					},
					1024: {
						slidesPerView: 6
					}
				}
      }
      
      setTimeout( () => {
          new Swiper(this.$refs.competitive as any, slideOpts)
      }, 101)

      this.isLoading = false
    },
    getItems: async function(category: string, limit: any, filter: string) {

      let endpoint

      endpoint = '/items/'+category+'?fields=*,portada.*,poster.*,genero.generos_id.nombre,distribuidora.*'

      if(filter !== null){
        endpoint+= '&'+filter
      }

      if( limit !== null ){
        endpoint+= '&limit='+limit
      }

      const response = await this.axios.get(this.server + endpoint)

      const items: any = response.data

      if(category == "peliculas"){
        items.data.forEach((movie: any,index: number) => {
            items.data[index].titulo = (movie.titulo_local) ? movie.titulo_local : movie.titulo_original

            if(movie.fecha_local !== null){
              items.data[index].fecha_local = this.getFormatedDate(movie.fecha_local)
            }else{
              items.data[index].fecha_local = 'A Confirmar'
            }

            /*if(movie.poster === null){
              items.data[index].poster = this.config.imagen_no_disponible
            }*/
        })
      }

      return items
    },
    getFormatedDate: function (date: any){
      date = new Date(date+'T12:00:00.000Z')
      date = new Intl.DateTimeFormat('es-ES', {dateStyle: 'medium', timeZone: 'America/Argentina/Buenos_Aires'}).format(date)
      return date
    },
    getThu: function(week: string){
      // set dates
      const today = new Date()
      let thisThu = new Date()
      let nextThu = new Date()
      const thuNmb = 4
      let todayNmb= today.getDay()

      // set number for sunday
      if( todayNmb == 0 ){
        todayNmb = 7
      }

      // if not default day number
      if( todayNmb != 4 ){
        const diff = ( todayNmb > thuNmb) ? (todayNmb - thuNmb) * -1 : thuNmb - todayNmb
        thisThu.setDate(today.getDate() + diff)
      }else{
        thisThu = today
      }

      // next thu
      nextThu.setDate(thisThu.getDate() + 7)

      let dd, mm, yyyy

      dd = String(thisThu.getDate()).padStart(2, '0')
      mm = String(thisThu.getMonth() + 1).padStart(2, '0')
      yyyy = thisThu.getFullYear()
      thisThu = yyyy + '-' + mm + '-' + dd as any 

      dd = String(nextThu.getDate()).padStart(2, '0')
      mm = String(nextThu.getMonth() + 1).padStart(2, '0')
      yyyy = nextThu.getFullYear();
      nextThu = yyyy + '-' + mm + '-' + dd as any 

      if( week == 'this'){
        return thisThu
      }else{
        return nextThu
      }
    }
  }
});
</script>

<style scoped>
ion-content{
  --padding-bottom: 0;
}

.swiper{
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.swiper-wrapper{
  height: 100%;
}

.col{
  width: 16.6%;
  height: initial;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-right: .01em solid lightgrey;
  background: white;
}

.col .date, .col .item{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  overflow: hidden;
  border: 4px solid white;
}

.col .date{
  background: var(--ion-color-primary, #3880ff);
  padding: 8px;
  position: sticky;
  top: 4px;
  min-height: 42px;
}

.col .item{
  height: 96px;
  background: #e0e0e0;
  overflow: hidden;
}

.col .item .header{
  background: #000;
  color: #fff;
  width: 100%;
  padding: 4px 0;
}

.col .item .title{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  color: black;
}

#loading{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#loading .loading-container{
  min-width: 300px;
}

#loading .loading-container .logo{
  margin-bottom: 24px;
}

ion-progress-bar{
  --background: #666;
}

.swiper-button-prev, .swiper-button-next{
  background: white;
  box-shadow: 0 2px 40px 0 rgba(23,41,64,.05),0 5px 10px 0 rgba(23,41,64,.1);
}

.swiper-button-prev{
  left: 0;
  border-radius: 0 8px 8px 0;
}

.swiper-button-next{
  right: 0;
  border-radius: 8px  0 0 8px;
}

.swiper-button-prev:after, .swiper-button-next:after{
  font-size: 24px;
  font-weight: bold;
  color: var(--ion-color-primary);
}

@media (max-width: 768px){
  .col{
    width: 33.33%;
  }
}
</style>